
import './aos';
import './background-images';
//import mrCountdown from './countdown';
//import mrCountup from './countup';
//import mrDropdownGrid from './dropdown-grid';
import './fade-page';
//import mrFlatpickr from './flatpickr';
import './flickity';
//import mrFormEmail from './form-email';
//import mrIonRangeSlider from './ion-rangeslider';
//import mrIsotope from './isotope';
import './jarallax';
//import mrMapsStyle from './maps-style';
//import mrMaps from './maps';
import mrOverlayNav from './overlay-nav';
//import './plyr';
import './popovers';
//import './prism';
//import mrReadingPosition from './reading-position';
import mrSmoothScroll from './smooth-scroll';
import mrSticky from './sticky';
//import mrTwitterFetcher from './twitter-fetcher';
//import mrTypedText from './typed-text';
import mrUtil from './util';
//import './wizard';

(() => {
  if (typeof $ === 'undefined') {
    throw new TypeError('Medium Rare JavaScript requires jQuery. jQuery must be included before theme.js.');
  }
})();

export {
//  mrCountdown,
//  mrCountup,
//  mrDropdownGrid,
//  mrFlatpickr,
//  mrFormEmail,
//  mrIonRangeSlider,
//  mrIsotope,
//  mrMapsStyle,
//  mrMaps,
  mrOverlayNav,
//  mrReadingPosition,
  mrSmoothScroll,
  mrSticky,
//  mrTwitterFetcher,
//  mrTypedText,
  mrUtil,
};
